<template>
  <div class="public-scroll-transparent public-page-style">
    <div class="body">
      <div class="userTree">
        <div class="title"></div>
        <div class="treeBox">
          <el-cascader :options="options" :props="props1" v-model="selectUserId" filterable clearable style="width:100%"
            @change="getChildProjectGroupTree">
          </el-cascader>
          <!-- <el-select
            filterable
            style="width: 100%"
            v-model="selectUserId"
            @change="getChildProjectGroupTree"
            placeholder="请选择"
          >
            <el-option
              v-for="item in projectGroupChangeArr"
              :key="item.userId"
              :label="item.fullName"
              :value="item.userId"
            >
            </el-option>
          </el-select> -->
          <div class="down-tree">
            <el-tree
            :check-on-click-node="true"
            :check-strictly="true"
            v-if="treeVisit"
            show-checkbox
            ref="tree"
            node-key="id"
            :highlight-current="true"
            :data="treeData"
            :props="props"
            :default-checked-keys="treeCheck"
            :default-expanded-keys="treeExpanded"
            @check="handleNodeClick"
            @node-expand="expand"
            @node-collapse="collapse"
          ></el-tree>
          </div>
        
        </div>
      </div>
      <div class="historyBox">
        <div class="historyTitle">
          <el-form :inline="true" :model="historyFrom" class="iot-form-inline">
            <el-form-item label="参数">
              <el-select
                v-model="historyFrom.dataApplicationId"
                placeholder="请选择"
                @change="dataConditionChange"
                size="mini"
              >
                <el-option
                  v-for="item in dataCondition"
                  :key="item.dataApplicationId"
                  :label="item.dataApplicationName"
                  :value="item.dataApplicationId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="日期" v-if="conditionType != ''">
              <el-date-picker
                size="mini"
                v-model="historyFrom.startDate"
                v-if="conditionType == 1"
                type="date"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                placeholder="选择日期"
              >
              </el-date-picker>
              <el-date-picker
                size="mini"
                v-model="historyFrom.startDate"
                v-if="conditionType == 2"
                type="month"
                value-format="yyyy-MM"
                :picker-options="pickerOptions"
                placeholder="选择日期"
              >
              </el-date-picker>
              <el-date-picker
                size="mini"
                v-model="historyFrom.startDate"
                v-if="conditionType == 3"
                type="year"
                value-format="yyyy"
                :picker-options="pickerOptions"
                placeholder="选择日期"
              >
              </el-date-picker>
              <el-date-picker
                size="mini"
                v-model="dataSpfvDate"
                v-if="conditionType == 4"
                type="daterange"
                range-separator="至"
                @change="getDataSpfvDate"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
              <!-- <el-date-picker
                v-model="historyFrom.startDate"
                v-if="conditionType == 4"
                type="date"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                placeholder="选择日期"
              >
              </el-date-picker>
              <el-date-picker
                v-model="historyFrom.endDate"
                v-if="conditionType == 4"
                type="year"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                placeholder="选择日期"
              >
              </el-date-picker> -->
            </el-form-item>

            <el-form-item>
              <el-button
                size="mini"
                type="success"
                icon="el-icon-search"
                @click="getApplicationHistory"
              ></el-button>
            </el-form-item>
          </el-form>

          <div style="margin-right: 50px">
            <div style="display: inline-block">
              <div class="switch">
                <div :class="{highlight:checkHistory}">
                  <el-dropdown class="ysList">
                    <img
                      v-show="conditionType != 4"
                      @click="historyCheck"
                      src="../../public/backImg/historyLogo.png"
                    />
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item> 数据应用柱状图 </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div :class="{highlight:!checkHistory}">
                  <el-dropdown class="ysList">
                    <img
                      @click="checkHistory = false"
                      src="../../public/backImg/formLogo.png"
                      alt=""
                    />
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item> 数据应用表格 </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <!-- <div>
                  <el-dropdown class="ysList">
                    <img src="../../public/backImg/download.png" alt="" />
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item> 数据应用下载 </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div> -->
              </div>
            </div>

            <div class="dataSpfvMoney">
              <div>
                <el-dropdown class="ysList">
                  <img
                    v-show="conditionType == 4"
                    @click="setDataSpfvMoney"
                    src="../../public/backImg/set.png"
                    alt=""
                  />
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>尖峰平谷价格设置</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
        <el-dialog
        top="3%"
          title="设置费用"
          :visible.sync="dialogVisible"
          width="75%"
          :before-close="handleClose"
        >
          <div style="width: 100%; height: 500px">
            <div class="date-title">
              <div
                v-for="item in 12"
                :key="item"
                @click="getDataSpfvMoney(item)"
                :class="{ monthCheck: dataSpfvMoneyDate == item }"
              >
                {{ item }}月
              </div>
            </div>
            <div class="dataSpfvMoney-body">
              <div>
                <div class="dataSpfvMoney-title">尖</div>
                <div class="dataSpfv-money">
                  <div>
                    单价：<el-input
                      style="width: 110px; height: 30px"
                      v-model="dataSpfvMoneyData.peakCost.sharpMoney"
                      placeholder="请输入内容"
                    ></el-input>
                  </div>
                </div>
                <div class="dataSpfvMoney-date">
                  <div class="dataSpfvMoney-item">
                    <div class="dataSpfvMoney-item-title">时间段(小时)</div>
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      @click="addDataSpfvData(1)"
                    ></el-button>
                  </div>
                  <div v-for="(item, index) in sharpData" :key="index">
                    <div class="dataSpfvMoney-item">
                      <el-input
                        @change="setTimes(item)"
                        style="width: 50px; height: 40px"
                        v-model="item.startTime"
                        placeholder="请输入内容"
                      ></el-input>
                      <div>-</div>
                      <el-input
                        @change="setTimes(item)"
                        style="width: 50px; height: 40px"
                        v-model="item.endTime"
                        placeholder="请输入内容"
                      ></el-input>
                      <el-button
                        type="danger"
                        @click="delDataSpfvMoney(item.spfvTimeId)"
                        icon="el-icon-delete"
                      ></el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="dataSpfvMoney-title">峰</div>
                <div class="dataSpfv-money">
                  <div>
                    单价：<el-input
                      style="width: 110px; height: 30px"
                      v-model="dataSpfvMoneyData.peakCost.peakMoney"
                      placeholder="请输入内容"
                    ></el-input>
                  </div>
                </div>
                <div class="dataSpfvMoney-date">
                  <div class="dataSpfvMoney-item">
                    <div class="dataSpfvMoney-item-title">时间段(小时)</div>
                    <el-button
                      type="primary"
                      @click="addDataSpfvData(2)"
                      icon="el-icon-plus"
                    ></el-button>
                  </div>
                  <div v-for="(item, index) in peakData" :key="index">
                    <div class="dataSpfvMoney-item">
                      <el-input
                        style="width: 50px; height: 40px"
                        v-model="item.startTime"
                        placeholder="请输入内容"
                      ></el-input>
                      <div>-</div>
                      <el-input
                        style="width: 50px; height: 40px"
                        v-model="item.endTime"
                        placeholder="请输入内容"
                      ></el-input>
                      <el-button
                        type="danger"
                        @click="delDataSpfvMoney(item.spfvTimeId)"
                        icon="el-icon-delete"
                      ></el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="dataSpfvMoney-title">平</div>
                <div class="dataSpfv-money">
                  <div>
                    单价：<el-input
                      style="width: 110px; height: 30px"
                      v-model="dataSpfvMoneyData.peakCost.flatMoney"
                      placeholder="请输入内容"
                    ></el-input>
                  </div>
                </div>
                <div class="dataSpfvMoney-date">
                  <div class="dataSpfvMoney-item">
                    <div class="dataSpfvMoney-item-title">时间段(小时)</div>
                    <el-button
                      @click="addDataSpfvData(3)"
                      type="primary"
                      icon="el-icon-plus"
                    ></el-button>
                  </div>
                  <div v-for="(item, index) in flatData" :key="index">
                    <div class="dataSpfvMoney-item">
                      <el-input
                        style="width: 50px; height: 40px"
                        v-model="item.startTime"
                        placeholder="请输入内容"
                      ></el-input>
                      <div>-</div>
                      <el-input
                        style="width: 50px; height: 40px"
                        v-model="item.endTime"
                        placeholder="请输入内容"
                      ></el-input>
                      <el-button
                        type="danger"
                        @click="delDataSpfvMoney(item.spfvTimeId)"
                        icon="el-icon-delete"
                      ></el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="dataSpfvMoney-title">谷</div>
                <div class="dataSpfv-money">
                  <div>
                    单价：<el-input
                      style="width: 110px; height: 30px"
                      v-model="dataSpfvMoneyData.peakCost.valleyMoney"
                      placeholder="请输入内容"
                    ></el-input>
                  </div>
                </div>
                <div class="dataSpfvMoney-date">
                  <div class="dataSpfvMoney-item">
                    <div class="dataSpfvMoney-item-title">时间段(小时)</div>
                    <el-button
                      @click="addDataSpfvData(4)"
                      type="primary"
                      icon="el-icon-plus"
                    ></el-button>
                  </div>
                  <div v-for="(item, index) in valleyData" :key="index">
                    <div class="dataSpfvMoney-item">
                      <el-input
                        style="width: 50px; height: 40px"
                        v-model="item.startTime"
                        placeholder="请输入内容"
                      ></el-input>
                      <div>-</div>
                      <el-input
                        style="width: 50px; height: 40px"
                        v-model="item.endTime"
                        placeholder="请输入内容"
                      ></el-input>
                      <el-button
                        type="danger"
                        @click="delDataSpfvMoney(item.spfvTimeId)"
                        icon="el-icon-delete"
                      ></el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="sumbitDataSpfv">确 定</el-button>
            <el-button @click="dialogVisible = false">取 消</el-button>
          </span>
        </el-dialog>
        <div class="historyBody" v-show="checkHistory">
          <div id="historyBar" ref="historyBar" 
          :style="`width: 1250px; height:775px;zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`"></div>
        </div>
        <div class="historyBody" v-if="!checkHistory && porHistry">
          <div class="tableList">
            <div class="porHistry-title">
              <div class="porHistry-title-name">{{ porHistryProjectName }}</div>
              <!-- <div class="porHistry-title-dataApplicationName">{{dataApplicationName}}</div> -->
              <div class="porHistry-title-close">
                <div @click="getApplicationHistory">
                  <img src="../../public/backImg/close.png" alt="" />
                </div>
              </div>
            </div>
            <div style="height: 80%">
              <el-table
                border
                v-if="conditionType == 4"
                :data="tableData"
                max-height="350"
              >
                <el-table-column prop="dateTime" fixed label="日期" width="180">
                </el-table-column>
                <el-table-column label="尖">
                  <el-table-column prop="sharpNum" label="电量" width="70">
                  </el-table-column>
                  <el-table-column prop="sharpMoney" label="单价" width="70">
                  </el-table-column>
                  <el-table-column prop="sharpNumMoney" label="金额" width="70">
                  </el-table-column>
                </el-table-column>
                <el-table-column label="峰">
                  <el-table-column prop="peakNum" label="电量" width="70">
                  </el-table-column>
                  <el-table-column prop="peakMoney" label="单价" width="70">
                  </el-table-column>
                  <el-table-column prop="peakNumMoney" label="金额" width="70">
                  </el-table-column>
                </el-table-column>
                <el-table-column label="平">
                  <el-table-column prop="flatNum" label="电量" width="70">
                  </el-table-column>
                  <el-table-column prop="flatMoney" label="单价" width="70">
                  </el-table-column>
                  <el-table-column prop="flatNumMoney" label="金额" width="70">
                  </el-table-column>
                </el-table-column>
                <el-table-column label="谷">
                  <el-table-column
                    prop="valleyNum"
                    label="电量"
                    width="70"
                  ></el-table-column>
                  <el-table-column prop="valleyMoney" label="单价" width="70">
                  </el-table-column>
                  <el-table-column
                    prop="valleyNumMoney"
                    label="金额"
                    width="70"
                  >
                  </el-table-column>
                </el-table-column>
                <el-table-column label="合计">
                  <el-table-column prop="spfvNum" label="电量">
                  </el-table-column>
                  <el-table-column prop="spfvNumMoney" label="单价">
                  </el-table-column>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="tips">
          温馨提醒：“以上数据信息仅供参考，受网络或上报周期等因素影响可能会产生1-2个上报周期的数据误差”
        </div>
        <div class="historyBody" v-if="!checkHistory && !porHistry">
          <div class="tableList">
            <div style="height: 45%" v-if="conditionType == 4">
              <el-table
                v-if="conditionType == 4"
                border
                :data="tableData"
                max-height="350"
              >
                <el-table-column fixed label="项目名称" width="180">
                  <template slot-scope="scope">
                    <span
                      class="titleProHistory"
                      @click="getApplicationProHistory(scope.row)"
                    >
                      {{ scope.row.projectName }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="尖">
                  <el-table-column prop="sharpNum" label="电量" width="70">
                  </el-table-column>
                  <el-table-column prop="sharpMoney" label="单价" width="70">
                  </el-table-column>
                  <el-table-column prop="sharpNumMoney" label="金额" width="70">
                  </el-table-column>
                </el-table-column>
                <el-table-column label="峰">
                  <el-table-column prop="peakNum" label="电量" width="70">
                  </el-table-column>
                  <el-table-column prop="peakMoney" label="单价" width="70">
                  </el-table-column>
                  <el-table-column prop="peakNumMoney" label="金额" width="70">
                  </el-table-column>
                </el-table-column>
                <el-table-column label="平">
                  <el-table-column prop="flatNum" label="电量" width="70">
                  </el-table-column>
                  <el-table-column prop="flatMoney" label="单价" width="70">
                  </el-table-column>
                  <el-table-column prop="flatNumMoney" label="金额" width="70">
                  </el-table-column>
                </el-table-column>
                <el-table-column label="谷">
                  <el-table-column
                    prop="valleyNum"
                    label="电量"
                    width="70"
                  ></el-table-column>
                  <el-table-column prop="valleyMoney" label="单价" width="70">
                  </el-table-column>
                  <el-table-column
                    prop="valleyNumMoney"
                    label="金额"
                    width="70"
                  >
                  </el-table-column>
                </el-table-column>
                <el-table-column label="合计">
                  <el-table-column prop="spfvNum" label="电量">
                  </el-table-column>
                  <el-table-column prop="spfvNumMoney" label="金额">
                  </el-table-column>
                </el-table-column>
              </el-table>
            </div>

            <div
              style="height: 45%"
              v-if="conditionType != 4 && conditionType != ''"
            >
              <el-table
                border
                v-if="conditionType == 1"
                :data="tableData"
                max-height="350"
              >
                <el-table-column
                  prop="projectName"
                  fixed
                  label="项目名称"
                  width="180"
                >
                </el-table-column>
                <el-table-column prop="01" label="1时"> </el-table-column>
                <el-table-column prop="02" label="2时"> </el-table-column>
                <el-table-column prop="03" label="3时"> </el-table-column>
                <el-table-column prop="04" label="4时"> </el-table-column>
                <el-table-column prop="05" label="5时"> </el-table-column>
                <el-table-column prop="06" label="6时"> </el-table-column>
                <el-table-column prop="07" label="7时"> </el-table-column>
                <el-table-column prop="08" label="8时"> </el-table-column>
                <el-table-column prop="09" label="9时"> </el-table-column>
                <el-table-column prop="10" label="10时"> </el-table-column>
                <el-table-column prop="11" label="11时"> </el-table-column>
                <el-table-column prop="12" label="12时"> </el-table-column>
              </el-table>

              <el-table
                border
                v-if="conditionType == 2"
                :data="tableData"
                max-height="350"
              >
                <el-table-column
                  prop="projectName"
                  fixed
                  label="项目名称"
                  width="180"
                >
                </el-table-column>
                <el-table-column prop="01" label="1日"> </el-table-column>
                <el-table-column prop="02" label="2日"> </el-table-column>
                <el-table-column prop="03" label="3日"> </el-table-column>
                <el-table-column prop="04" label="4日"> </el-table-column>
                <el-table-column prop="05" label="5日"> </el-table-column>
                <el-table-column prop="06" label="6日"> </el-table-column>
                <el-table-column prop="07" label="7日"> </el-table-column>
                <el-table-column prop="08" label="8日"> </el-table-column>
                <el-table-column prop="09" label="9日"> </el-table-column>
                <el-table-column prop="10" label="10日"> </el-table-column>
                <el-table-column prop="11" label="11日"> </el-table-column>
              </el-table>
              <el-table
                border
                v-if="conditionType == 3"
                :data="tableData"
                max-height="350"
              >
                <el-table-column
                  prop="projectName"
                  fixed
                  label="项目名称"
                  width="180"
                >
                </el-table-column>
                <el-table-column prop="01" label="1月"> </el-table-column>
                <el-table-column prop="02" label="2月"> </el-table-column>
                <el-table-column prop="03" label="3月"> </el-table-column>
                <el-table-column prop="04" label="4月"> </el-table-column>
                <el-table-column prop="05" label="5月"> </el-table-column>
                <el-table-column prop="06" label="6月"> </el-table-column>
                <el-table-column prop="07" label="7月"> </el-table-column>
                <el-table-column prop="08" label="8月"> </el-table-column>
                <el-table-column prop="09" label="9月"> </el-table-column>
                <el-table-column prop="10" label="10月"> </el-table-column>
                <el-table-column prop="11" label="11月"> </el-table-column>
                <el-table-column prop="12" label="12月"> </el-table-column>
              </el-table>
            </div>
            <div
              style="height: 45%"
              v-if="conditionType == 2 || conditionType == 1"
            >
              <el-table
                border
                v-if="conditionType == 1 && conditionType != 4"
                :data="tableData"
                max-height="350"
                style="margin-top: 10px"
              >
                <el-table-column
                  prop="projectName"
                  fixed
                  label="项目名称"
                  width="180"
                >
                </el-table-column>

                <el-table-column prop="13" label="13时" width="90">
                </el-table-column>
                <el-table-column prop="14" label="14时" width="90">
                </el-table-column>
                <el-table-column prop="15" label="15时" width="90">
                </el-table-column>
                <el-table-column prop="16" label="16时" width="90">
                </el-table-column>
                <el-table-column prop="17" label="17时" width="90">
                </el-table-column>
                <el-table-column prop="18" label="18时" width="90">
                </el-table-column>
                <el-table-column prop="19" label="19时" width="90">
                </el-table-column>
                <el-table-column prop="20" label="20时" width="90">
                </el-table-column>
                <el-table-column prop="21" label="21时" width="90">
                </el-table-column>
                <el-table-column prop="22" label="22时" width="90">
                </el-table-column>
                <el-table-column prop="23" label="23时" width="90">
                </el-table-column>
                <el-table-column prop="00" label="24时" width="90">
                </el-table-column>
              </el-table>
              <el-table
                border
                v-if="conditionType == 2 && conditionType != 4"
                :data="tableData"
                max-height="350"
                style="margin-top: 10px"
              >
                <el-table-column
                  prop="projectName"
                  fixed
                  label="项目名称"
                  width="180"
                >
                </el-table-column>
                <el-table-column prop="12" label="12日" width="107">
                </el-table-column>
                <el-table-column prop="13" label="13日" width="107">
                </el-table-column>
                <el-table-column prop="14" label="14日" width="107">
                </el-table-column>
                <el-table-column prop="15" label="15日" width="107">
                </el-table-column>
                <el-table-column prop="16" label="16日" width="107">
                </el-table-column>
                <el-table-column prop="17" label="17日" width="107">
                </el-table-column>
                <el-table-column prop="18" label="18日" width="107">
                </el-table-column>
                <el-table-column prop="19" label="19日" width="107">
                </el-table-column>
                <el-table-column prop="20" label="20日" width="107">
                </el-table-column>
                <el-table-column prop="21" label="21日" width="107">
                </el-table-column>
              </el-table>
            </div>
            <div
              style="height: 45%"
              v-if="conditionType == 2 && conditionType != 4"
            >
              <el-table
                border
                v-if="conditionType == 2"
                :data="tableData"
                max-height="350"
              >
                <el-table-column
                  prop="projectName"
                  fixed
                  label="项目名称"
                  width="180"
                >
                </el-table-column>
                <el-table-column prop="22" label="22日"> </el-table-column>
                <el-table-column prop="23" label="23日"> </el-table-column>
                <el-table-column prop="24" label="24日"> </el-table-column>
                <el-table-column prop="25" label="25日"> </el-table-column>
                <el-table-column prop="26" label="26日"> </el-table-column>
                <el-table-column prop="27" label="27日"> </el-table-column>
                <el-table-column prop="28" label="28日"> </el-table-column>
                <el-table-column prop="29" label="29日"> </el-table-column>
                <el-table-column prop="30" label="30日"> </el-table-column>
                <el-table-column prop="31" label="31日"> </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import * as echarts from "echarts";
let that;

export default {
  name: "History",
  data: function () {
    return {
      options:[],
      props1: {
          lazy: true,
          checkStrictly: true,
          emitPath: false,
          lazyLoad(node, resolve) {
            that.getChildProjectGroupTree(node.value)
            return that.getlazyLoad(node, resolve)
          }
        },
      zoom:1,
      dataApplicationName: "",
      porHistry: false,
      porHistryProjectName: "",
      dataSpfvMoneyDate: 1,
      sharpData: [],
      peakData: [],
      flatData: [],
      valleyData: [],
      dataSpfvMoneyData: {
        peakCost: {
          spfvId: 1, //钱id
          sharpMoney: 1, //尖钱
          peakMoney: 1, //峰钱
          flatMoney: 1, //平钱
          valleyMoney: 1, //谷钱
          monthNum: 1, //月份
          modifyTime: "",
        },
        peakSection: [
          {
            spfvTimeId: 12, //id
            spfvId: 1, //钱id
            spfvType: 4,
            startTime: 21,
            endTime: 24,
          },
        ],
      },
      dataSpfvDate: "",
      dialogVisible: false,
      flag: false,
      treeExpanded: [],
      treeCheck: [],
      treeVisit: true,
      nodeCheck: [],
      dataCondition: {},
      checkHistory: false,
      selectUserId: "",
      projectGroupChangeArr: "",
      treeData: [],
      props: {},
      paramList: {},
      conditionType: "",
      historyFrom: {
        projectId: "",
        dataApplicationId: "",
        startDate: "",
        endDate: "",
      },
      historyData: [],
      tableData: [],
      historyDate: "",
      //设置时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      getBar(data, x, title, ref) {
        let option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: title,
          },
          xAxis: {
            data: x,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          yAxis: {
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: "#999",
            },
          },
          dataZoom: [
            {
              type: "inside",
            },
          ],
          series: data,
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
    };
  },
  methods: {
    getlazyLoad(node, resolve) {
        const { level } = node;
        let type = level == 0 ? that.$store.state.userId : node.value
        const nodes = []
        // if (level == 1 && type == that.$store.state.userId) {
        //   node.loading = false
        //   node.loaded=true
        //   return
        // }
        that.$api.projectGroup.searchUserChild(type).then((res) => {
          if (res.code == 200) {
            node.loading = false
            node.loaded = true
            if (res.data.length > 0) {
              res.data.map((item) => {
                let obj = {
                  value: item.userId,
                  label: item.fullName,
                  leaf: !item.children
                }
                nodes.push(obj)
              })
              if (level == 0) {
                nodes.unshift({ value: that.$store.state.userId, label: that.$store.state.name, leaf: true })
                that.options = nodes
              }
            }
            resolve(nodes);
          }
        })
      },
    setTimes(item) {
      if (item.startTime != "" && item.endTime != "") {
        if (item.endTime < item.startTime) {
          that.$message({
            type: "warning",
            message: "请输入正确的时间段！",
          });
          return;
        }
      }
      that.dataSpfvMoneyData.peakSection.forEach((ele) => {
        if (ele.startTime < item.startTime && item.startTime < ele.endTime) {
          that.$message({
            type: "warning",
            message: "输入的时间段不能重复！",
          });
          return;
        }
        if (ele.startTime < item.endTime && item.endTime < ele.endTime) {
          that.$message({
            type: "warning",
            message: "输入的时间段不能重复！",
          });
          return;
        }
      });
    },

    getDataSpfvDate(value) {
      that.historyFrom.startDate = value[0];
      that.historyFrom.endDate = value[1];
    },

    delDataSpfvMoney(id) {
      let arr = that.dataSpfvMoneyData.peakSection.filter((item) => {
        if (item.spfvTimeId != id) {
          return item;
        }
      });
      that.dataSpfvMoneyData.peakSection = arr;
      console.log(that.dataSpfvMoneyData.peakSection);
      that.dataSpfvMoneyList();
    },

    sumbitDataSpfv() {
      that.$api.dataApplication
        .putDataSpfvMoney(that.dataSpfvMoneyData)
        .then((res) => {
          if (res.code == 200 && res.data) {
            that.getDataSpfvMoney(that.dataSpfvMoneyDate);
            that.$message({
              type: "success",
              message: "保存成功！",
            });
          }
        });
    },
    addDataSpfvData(type) {
      let id =
        that.dataSpfvMoneyData.peakSection[
          that.dataSpfvMoneyData.peakSection.length - 1
        ].spfvTimeId || 0;
      that.dataSpfvMoneyData.peakSection.push({
        spfvTimeId: id + 1,
        spfvType: type,
        startTime: "",
        endTime: "",
      });
      that.dataSpfvMoneyList();
    },
    dataSpfvMoneyList() {
      that.sharpData = [];
      that.peakData = [];
      that.flatData = [];
      that.valleyData = [];
      that.dataSpfvMoneyData.peakSection.forEach((item) => {
        if (item.spfvType == 1) {
          that.sharpData.push(item);
        } else if (item.spfvType == 2) {
          that.peakData.push(item);
        } else if (item.spfvType == 3) {
          that.flatData.push(item);
        } else if (item.spfvType == 4) {
          that.valleyData.push(item);
        }
      });
    },
    handleClose(done) {
      this.$confirm("关闭时不会保存您更改的数据，确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    dataConditionChange(data) {
      that.checkHistory = true;

      setTimeout(() => {
        that.checkHistory = false;
        that.dataCondition.forEach((item) => {
          if (item.dataApplicationId == data) {
            that.conditionType = item.conditionType;
            if (that.conditionType == 4) {
              that.checkHistory = false;
            }
            that.getDate();
          }
        });

        that.getApplicationHistory();
      }, 66);
    },

    historyCheck() {
      that.checkHistory = true;
      that.getHistoryData();
    },

    getHistoryData() {
      that.checkHistory = true;
      let all = [];
      let xAxis;
      let key;
      let title = [];
      that.tableData.forEach((item) => {
        let x = [];
        let arr = [];
        if (that.conditionType == 1) {
          for (let i = 0; i < 24; i++) {
            arr.push("0");
            x.push(i + 1);
          }
        } else if (that.conditionType == 2) {
          for (let i = 0; i < 31; i++) {
            arr.push("0");
            x.push(i + 1);
          }
        } else if (that.conditionType == 3) {
          for (let i = 0; i < 12; i++) {
            arr.push("0");
            x.push(i + 1);
          }
        }
        key = Object.keys(item).filter((i) => {
          if (i != "projectName") {
            return i;
          }
        });
        key.forEach((ele) => {
          let num = ele * 1 - 1;
          if (ele == 0) {
            num = 24;
          }
          arr[num] = item[ele];
        });
        title.push(item.projectName);
        all.push({
          name: item.projectName,
          type: "bar",
          showBackground: false,
          data: arr,
        });
        xAxis = x;
      });
      that.getBar(all, xAxis, title, "historyBar");
      // document.getElementById("historyBar").style.zoom =
      //   document.body.scrollHeight / 969;
    },

    //   获取用户列表
    getChildProjectGroupNoPage: function () {
      that.selectUserId = window.localStorage.getItem("cId") * 1;
      that.getChildProjectGroupTree(that.selectUserId);
      // that.$api.projectGroup.getChildUserNoPage().then((response) => {
      //   if (200 == response.code) {
      //     that.projectGroupChangeArr = response.data;
      //     that.selectUserId = that.projectGroupChangeArr[0].userId;
      //     that.getChildProjectGroupTree(that.projectGroupChangeArr[0].userId);
      //   }
      // });
    },
    // 获取项目树
    getChildProjectGroupTree: function (userId) {
      document.querySelectorAll(".el-cascader-node__label").forEach(el => {
          el.onclick = function () {
            if (this.previousElementSibling) this.previousElementSibling.click();
          };
      });
      if (!userId) return
      that.$api.projectGroup
        .getChildProjectGroupTree(userId)
        .then((response) => {
          if (200 == response.code && response.data[0]) {
            let tree = [];
            let planId=''
            response.data.find((item) => {
              if (item.children.length>0) {
                planId = item.children[0].planId;
              }
            })
            response.data.forEach((element) => {
              let ele = [];
              if (element.children.length > 0) {
                element.children.forEach((item) => {
                ele.push({
                  id: item.projectId,
                  label: item.projectName,
                  planId: item.planId,
                  disabled:planId!=item.planId,
                });
              });
              }
              tree.push({
                id: element.projectGroupId,
                label: element.projectGroupName,
                disabled: true,
                children: ele,
              });
            });
            that.treeData = tree;
         
            that.treeData.find((item) => {
              if (item.children.length > 0) {
                that.treeCheck = [item.children[0].id]
                that.treeExpanded = [item.id];
            }
          })
            // that.$refs.tree.setCheckedKeys([that.treeData[0].children[0].id]);
            if (planId) {
              that.getplanIdProtocolList(planId);
            } else {
              that.tableData=[]
            }
          }
        });
    },
    expand(data) {
      that.treeExpanded.push(data.id);
    },
    collapse(data) {
      that.treeExpanded = that.treeExpanded.filter((item) => {
        if (item != data.id) {
          return item;
        }
      });
    },

    //树状结构点击事件
    handleNodeClick: function (data) {
      that.treeVisit = false;
      let check = this.$refs.tree.getCheckedNodes();
      let prlanId;
      let arr = [];

      check.forEach((item) => {
        if (!item.children) {
          prlanId = item.planId;
          arr.push(item.id);
        }
      });
      if (arr.length == 0) {
        that.historyFrom.dataApplicationId = "";
      }
      if (arr.length == 1) {
        that.getplanIdProtocolList(prlanId);
      }
      that.treeCheck = arr;
      that.tableData = [];
      if (that.historyFrom.dataApplicationId != "") {
        that.getApplicationHistory();
      } else {
        that.conditionType = "";
      }

      if (arr.length >= 5) {
        that.treeData.forEach((item) => {
          item.children.forEach((ele) => {
            ele.disabled = true;
            arr.forEach((ite) => {
              if (ite == ele.id) {
                ele.disabled = false;
              }
            });
          });
        });
      } else {
        if (arr.length > 0) {
          that.treeData.forEach((item) => {
            item.children.forEach((ele) => {
              if (ele.planId != prlanId) {
                ele.disabled = true;
              } else {
                ele.disabled = false;
              }
            });
          });
        } else {
          that.treeData.forEach((item) => {
            item.children.forEach((ele) => {
              ele.disabled = false;
            });
          });
        }
      }
      setTimeout(() => {
        that.treeVisit = true;
      }, 66);
    },
    getplanIdProtocolList(prlanId) {
      that.$api.dataApplication.getplanIdProtocolList(prlanId).then((res) => {
        if (res.code == 200) {
          that.dataCondition = res.data;
          if (res.data.length > 0) {
            that.historyFrom.dataApplicationId=res.data[0].dataApplicationId;
          that.dataConditionChange(that.historyFrom.dataApplicationId);
          }
        }
      });
    },
    getApplicationHistory() {
      that.porHistry = false;
      if (that.treeCheck.length == 0) {
        that.$message({
          type: "error",
          message: "请选择项目！",
        });
        return;
      }
      if (that.historyFrom.dataApplicationId == "") {
        that.$message({
          type: "error",
          message: "请选择参数！",
        });
        return;
      }
      let startDate = new Date(that.historyFrom.startDate).getTime();
      let endDate = new Date(that.historyFrom.endDate).getTime();
      let times = new Date(endDate - startDate).getTime();
      let mounth = new Date("1970-2-2").getTime();
      if (mounth - times < 0) {
        that.$message({
          type: "error",
          message: "请选择31天内的日期",
        });
        return;
      }
      let data = {
        proList: that.treeCheck,
        dataApplicationId: that.historyFrom.dataApplicationId,
        startDate: that.historyFrom.startDate,
        endDate: that.historyFrom.endDate,
      };

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that.$api.dataApplication.getApplicationHistory(data).then((res) => {
        if (res.code == 200) {
          that.tableData = res.data;
          loading.close();
          if (that.checkHistory) {
            that.getHistoryData();
          }
        }
      });
    },
    getApplicationProHistory(proList) {
      that.dataCondition.forEach((item) => {
        if (item.dataApplicationId == that.historyFrom.dataApplicationId) {
          that.dataApplicationName = item.dataApplicationName;
        }
      });
      that.porHistry = true;
      that.porHistryProjectName = proList.projectName;
      let data = {
        proList: [proList.projectId],
        dataApplicationId: that.historyFrom.dataApplicationId,
        startDate: that.historyFrom.startDate,
        endDate: that.historyFrom.endDate,
      };
      that.$api.dataApplication.getApplicationProHistory(data).then((res) => {
        if (res.code == 200) {
          that.tableData = res.data;
        }
      });
    },
    setDataSpfvMoney() {
      that.dialogVisible = true;
      that.getDataSpfvMoney(1);
    },
    //获取尖峰平谷价钱
    getDataSpfvMoney(item) {
      that.dataSpfvMoneyDate = item;
      that.$api.dataApplication
        .getDataSpfvMoney(that.dataSpfvMoneyDate)
        .then((res) => {
          that.dataSpfvMoneyData = res.data;
          that.dataSpfvMoneyList();
        });
    },
    getDate() {
      let date = new Date();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let dates = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      if (that.conditionType == 1) {
        that.historyFrom.startDate =
        date.getFullYear() + "-" + month + "-" + dates;
      } else if (that.conditionType == 2) {
        that.historyFrom.startDate = date.getFullYear() + "-" + month;
      } else if (that.conditionType == 3) {
        that.historyFrom.startDate = `${date.getFullYear()}`;
      } else if (that.conditionType == 4) {
        that.dataSpfvDate = [
          date.getFullYear() + "-" + month + "-" + dates,
          date.getFullYear() + "-" + month + "-" + dates,
        ];

        that.historyFrom.startDate =
          date.getFullYear() + "-" + month + "-" + dates;
        that.historyFrom.endDate =
          date.getFullYear() + "-" + month + "-" + dates;
      }
    },
  },
  created() {
    that = this;
  },
  mounted() {
    this.zoom = 1 / document.body.style.zoom;
    window.addEventListener('resize', () => {
      this.zoom = 1 / document.body.style.zoom;
    })
    that.getChildProjectGroupNoPage();
  },
};
</script>

<style scoped>
.body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
}
.body > div {
  flex-shrink: 0;
  flex-grow: 0;
  box-sizing: border-box;
}
.userTree {
  width: 20%;
  padding: 10px 20px;
  height: 100%;
  box-shadow: 0 2px 12px 0 #dfdfdf;
}
.historyBox {
  padding: 10px 30px;
  width: 80%;
}
.historyTitle {
  width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.switch {
  width: 110px;
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
}
.switch div {
  width: 30px;
  height: 25px;
  box-sizing: border-box;
  cursor: pointer;
}
.switch div img {
  height: 100%;
}
.check {
  background: transparent !important;
}
.historyBody {
  width: 100%;
  height: 92%;
  box-shadow: 0 2px 12px 0 #dfdfdf;
  padding: 10px 0;
}
.historyBody .tableList {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 5px;
  box-sizing: border-box;
}
.tableList::-webkit-scrollbar {
  width: 10px;
  /*对垂直流动条有效*/
  height: 10px;
  /*对水平流动条有效*/
}

/*定义滑块颜色、内阴影及圆角*/
.tableList::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #aaaaaacc;
}
#historyBar {
  width: 1250px;
  height: 775px;
}
::v-deep .is-checked {
  color: #409eff !important;
}
/* ::v-deep .el-table__header {
  width: 100% !important;
} */
.table {
  width: 300px !important;
}
::v-deep .cell {
  text-align: center !important;
  font-weight: bolder;
}
.treeBox {
  margin-top: 20px;
  height: 800px;
  overflow-y: auto;
}
.treeBox::-webkit-scrollbar {
  width: 10px;
  /*对垂直流动条有效*/
  height: 10px;
  /*对水平流动条有效*/
}

/*定义滑块颜色、内阴影及圆角*/
.treeBox::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #aaaaaacc;
}
.dataSpfvMoney {
  width: 30px;
  height: 25px;
  margin-left: 10px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
}
::v-deep .el-form-item {
  margin: 0 !important;
  margin-right: 10px !important;
}

.date-title,
.dataSpfvMoney-body {
  display: flex;
  flex-wrap: nowrap;
  border-top: 1px solid rgb(217, 217, 217);
  padding: 10px;
}
.date-title div {
  width: 80px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  background-color: rgb(217, 217, 217);
  line-height: 30px;
  margin: 0 10px;
  cursor: pointer;
  position: relative;
}
.monthCheck {
  background-color: #409eff !important;
  color: #000 !important;
  transition: all 1s;
}
.date-title div::before {
  position: absolute;
  left: 0;
  bottom: -12px;
  content: "";
  width: 100%;
  height: 3px;
  background-color: transparent;
  transition: all 1s;
}
.monthCheck::before {
  background-color: #409eff !important;
  transition: all 1s;
}

.dataSpfvMoney-body {
  justify-content: space-around;
}
.dataSpfvMoney-body > div {
  width: 22%;
  line-height: 30px;
}
.dataSpfvMoney-title {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #000;
}
.dataSpfv-money {
  display: flex;
  justify-content: center;
}
.dataSpfv-money ::v-deep .el-input__inner {
  height: 100%;
}
.dataSpfvMoney-item ::v-deep .el-input__inner {
  height: 100%;
}
.dataSpfvMoney-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 40px;
  height: 40px;
  margin: 10px 0;
  align-items: center;
}
.dataSpfvMoney-item div {
  text-align: center;
}
.dataSpfvMoney-item-title {
  width: 70%;
  font-size: 14px;
  font-weight: bolder;
  line-height: 40px;
  text-align: center;
}
.porHistry-title {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  margin-bottom: 15px;
}
.porHistry-title div {
  width: 30%;
  font-weight: bolder;
}
.porHistry-title-name {
  text-align: left;
}
.porHistry-title-dataApplicationName {
  text-align: center;
}
.porHistry-title-close {
  display: flex;
  justify-content: flex-end;
}
.porHistry-title-close div {
  width: 20px;
  cursor: pointer;
}
.titleProHistory {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #409eff;
  cursor: pointer;
}
.tips {
  color: #999;
  font-size: 12px;
  margin: 5px 0;
}
.highlight{
  box-shadow: 0 4px 0  red;
}
</style>
